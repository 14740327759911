import '../styles/blog-post.scss';

import { Button, Typography } from 'antd';
import { navigate } from 'gatsby';
import React from 'react';

import BlogPostItem from '../components/blog-post-item';
import Boilerplate from '../components/boilerplate';
import SectionHeading from '../components/common/section-heading';
import Image from '../components/image';
import { Box, Col, Container, Row, Section } from '../components/layout';
import { richTextToReactComponents } from '../components/utils';
import { PageLocalContext } from '../context';
import { BlogPostTemplateContext, PageThemeMode, StaticPageId } from '../data';
import { useLinkResolver } from '../hooks';
import { Template } from '../types';
import { formatDate } from '../utils';

const BlogPostTemplate: Template<BlogPostTemplateContext> = ({
  pageContext: { id, data, suggestions },
}) => {
  const linkResolver = useLinkResolver();

  return (
    <PageLocalContext.Provider value={{ pageId: StaticPageId.Blog }}>
      <Boilerplate
        seoId={data.seo.id}
        pageId={id}
        pageTheme={{
          navigationMode: PageThemeMode.Dark,
        }}
      >
        <Section className="blog-post__heading-section">
          <Container>
            {data.subtitle && (
              <Typography.Paragraph className="blog-post__heading-section-subtitle">
                {data.subtitle}
              </Typography.Paragraph>
            )}

            <Typography.Title level={3}>{data.title}</Typography.Title>

            <time>{formatDate(data.date)}</time>

            <Box className="blog-post__image-container">
              <Image id={data.image.id} />
            </Box>
          </Container>
        </Section>

        <Section className="blog-post__content-section">
          <Container>{richTextToReactComponents(data.content)}</Container>
        </Section>

        <Section className="blog-post__footer-section">
          <Container>
            <Box direction="column" align="middle">
              <SectionHeading title="Das könnte Ihnen auch gefallen" />

              <Row gutters="4rem">
                {suggestions.map((post, index) => (
                  <Col
                    span={{ sm: 24, md: 24 / suggestions.length }}
                    key={index}
                  >
                    <BlogPostItem item={post} />
                  </Col>
                ))}
              </Row>

              <Button
                className="enhanced-button"
                type="primary"
                onClick={() => navigate(linkResolver(StaticPageId.Blog))}
                aria-label="Alle posts"
              >
                Alle posts
              </Button>
            </Box>
          </Container>
        </Section>
      </Boilerplate>
    </PageLocalContext.Provider>
  );
};

export default BlogPostTemplate;
